import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import date from 'date-and-time';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { updatePrePopCookieValue, fireFOS_DlEvent, submitForm, mapAdditionalFields, feedDataToSessionStorage, loadLocalStorageData, unescapeHTML, userData, fireEvent, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import { initValues, processJSONParameter } from '../common/configTools';
import { higherEducation } from '../common/constants';
import SelectField from '../UopxNativeSelectField';

const formRef = {}; 
let headerDesktop, descriptionDesktop, headerMobile, descriptionMobile, thankyouMessageTitleMobile, thankyouMessageTitleDesktop, thankyouMessageDescriptionMobile, thankyouMessageDescriptionDesktop;
let ctaText = '<span style="font-size: 15px; font-weight: 500; line-height: 24px;">Get your free handbook</span>';
 const initialEmailFormValues = {
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    highestLevelOfEducation: '',
    flex_more_info__c: '',
    flex_Info_Requested__c: 'something_else',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    flex_EmailCaptureSource: 'ADMG',
    userAgent: '',
    campaign: 'PHXEDU10',
    source: 'INET',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'This information is available on <a tabindex="0" href="https://www.phoenix.edu/">phoenix.edu</a> but has been organized into one central source for you to reference quickly and easily. By submitting this form, you consent to receiving University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy_policy.html">Privacy Policy</a> for more information.',
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
let device;
let tcValue = '';
const isMobileView = window.innerWidth <= 640;
const isTabletView = !isMobileView && window.innerWidth <= 990;
let formStep;
let formType;
let formName;
let formLocation;
let prevHighestEduLevel;

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}

function trapFocus(element, focusElmtValue) {
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }

class AdmissionsGuideForm extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
            highestLevelOfEducationlist: processJSONParameter([...higherEducation]),
        };
        this.state = this.initialState;
        this.ref = {
            container: React.createRef(),
            form: React.createRef(),
        };
        this.stepperRef = React.createRef();
    }
    reset() {
        this.setState(this.initialState);
    }

    componentDidMount() {
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues.flex_EmailCaptureSource = 'ADMG';
        initialValues.thankyouPageUrl= '';
        initValues(initialValues, this.props);
        loadLocalStorageData(initialValues); 
        this.setState({ initialValues }); 
        //this.setState({ isSubmit: true }); 
        getIPAddress(this.updateIPAddress);
        
        if (isMobileView){device = 'Mobile';}else {device = 'Desktop';}
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Preprospect Zero Party';
        formLocation = 'Zero Party Admissions Guide';
    	formName = this.props.formname || 'Admissions Guide ';
        formName = formName + device;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formLocation"] = formLocation;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }

        // KEYDOWN EVENT
        if (document.querySelector('.abandonment input[name="fullName"]')) {
            document.querySelector('.abandonment input[name="fullName"]').addEventListener("keydown", function(e) {
                if(e.keyCode == 9 || e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {} 
                    else {
                        setTimeout(function () {
                            if (document.querySelector('[id^="fullName"][id$="-helper-text"]')) {
                                document.querySelector('input[name="emailAddress"]').focus()
                            }
                        }, 300);
                    }
                }
            });
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.requestingIP = ipAddress;
        this.setState({ initialValues });    
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireEvent('PreprospectFormStart', 'Zero Party', formName, formType, 'Start Admissions Guide modal', formLocation);
            this.setState({ rfi_begin: true });
        }
      }    
      
    isTransferCredit = (event, checked) => {
        formRef.current.values.flex_Transfer_Credits_from_ValueExchange__c = checked;
        if (checked === true) {
            tcValue='checked';
        }
        if (checked === false) {
            tcValue='unchecked';
        }      
    }

    handleSubmit = async values => {
      if (document.activeElement.id === "AGbladeModalSubmit") {
        // This change add the session token on submit specifically for blade only forms as the banner will have already triggered this condition when clicked
        if (sessionStorage.getItem('showModalAG') === null || sessionStorage.getItem('showModalAG') === undefined || sessionStorage.getItem('showModalAG') === false) {
            sessionStorage.setItem('showModalAG', true);
        }
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }    
        this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'highestLevelOfEducation': values.higherEducation});
        window.uopSegment&&window.uopSegment.track('Admissions Guide Submitted', {'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'highestLevelOfEducation': values.higherEducation, 'analytics': {'mcid': ecidValue}});
        //window.uopxDataLayer&&window.uopxDataLayer.track('PreProspectInfoSubmitted', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        srSpeak('email submitted');
        //submitForm(values, this.props);
        //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //this.setState({ isSubmit: true, currentStep:2}); 
        mapAdditionalFields(values);
        feedDataToSessionStorage(values);
        values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
        Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

        let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
        }
        // this.setState({ isSubmit: true, currentStep: 2 }); // TEST
        try {
            const response = await fetch(postURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
            });

            this.setState({
                initialValues: values,
                isSubmit: true,
                currentStep:2,
                message: null,
            });

            if (window.uopxDataLayer && window.uopxDataLayer.track) {
                window.uopxDataLayer&&window.uopxDataLayer.track('PreprospectFormSubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Submit Admissions Guide modal', 'location': formLocation, 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress, 'highestLevelOfEducation': values.higherEducation},},});
            }
        } catch (e) {
            this.setState({
                message: <div className="">{e.message || e}</div>,
            });
        }
        if (window.FS) {
            preProspectInfoData.source= "AdmissionsGuideForm";
            //calling FS and passing the tracking data set
            window.FS.event('Submitted PreProspectInfo', preProspectInfoData);
        }

        document.querySelectorAll('.agCopy').forEach((element) => {
            element.style.display='none';
        });
        document.querySelectorAll('.agThanksCopy').forEach((element) => {
            element.style.display='block';
        });
        document.querySelectorAll('.agThanksCopyExt').forEach((element) => {
            element.style.display='block';
        });
        // Handle Image Switching
        document.querySelectorAll('.agImageForm').forEach((element) => {
            element.style.display = 'none';
        });
        document.querySelectorAll('.agImageThanks').forEach((element) => {
            element.style.display = 'block';
        });
        document.querySelector('#agThanksCopy h2').setAttribute('tabIndex', '-1');
        document.querySelector('#agThanksCopy h2').focus();
        if (isMobileView) {
            document.querySelector('#AdmissionsGuideContainer').scrollIntoView({ behavior: "auto", block: "center", inline: "center" });  // #AdmissionsGuideContainer is an AEM container id
        } 
      }   
    }
    
    validateAdmissionsGuideForm(values) {
        const errors = {};
        if ((!values.fullName) || (values.fullName.trim().indexOf(' ') === -1)) {
            errors.fullName = 'Enter first and last name';
          } else if (!profanityCheck(values.fullName)) {
            errors.fullName = 'Full Name appears invalid';
          }
        if (!values.emailAddress) {
          errors.emailAddress = 'Enter email address';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email appears invalid';
        }
        return errors;
    }

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'higherEducation') {
            if (value && prevHighestEduLevel !== value) {
                updatePrePopCookieValue('higherEducation', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevHighestEduLevel = value;
                initialValues.highestLevelOfEducation = value
                //validateOneStep(initialValues);
            }
        }
    }

    handleDownload =(e)=> {
        const pdfUrl = 'https://www.phoenix.edu/content/dam/edu/guides/uopx-admissions-handbook-2025.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.role = 'link'
        link.target='_blank';
        link.download = "Admissions-guide.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.uopxDataLayer.track("PreprospectDownloadButtonClicked",{
            'componentName' : 'Zero Party',
            'name': formName, 
            'type': formType,
            'text': 'Download Admissions Guide modal',
            'location': formLocation
        });
    }
    
    keydownDownload = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleDownload;
        }         
    }

    modalClose = () => {
        let modal = document.querySelector("#AdmissionsGuideContainer");
        let overlay = document.querySelector("#overlay-div");
        let exitModal = document.querySelector("#AdmissionsGuideContainer.exitmodal");
        if (document.querySelector('.admGuideAbandonment')) {
            if (modal !== null || overlay !== null){
                modal.classList.add("hidden");
                overlay.classList.add("hidden");
            }
        }
        else if (document.querySelector('.admGuideBannerModal')) {
            if (overlay !== null) {
                document.querySelector('#overlay-div.hidden') ? console.log("#overlay-div already contains 'hidden'") : overlay.classList.add("hidden");
            }
            if (modal !== null) {
                if (exitModal !== null) {
                    exitModal.classList.add("hidden");
                } else {
                    modal.removeAttribute("style");
                    modal.removeAttribute("tabIndex");
                    if (document.querySelector('#AdmissionsGuideContainer .mobileFormContainer').style.length > 0) {
                        document.querySelector('#AdmissionsGuideContainer .mobileFormContainer').removeAttribute("style");
                        document.querySelector('#AdmissionsGuideContainer').classList.remove("AGModalOpen");
                    }
                    document.querySelectorAll('#AdmissionsGuideContainer .modalagstack').forEach((element) => {
                        element.removeAttribute("style");
                    });
                    if (document.querySelector('#AdmissionsGuideContainer .columns').style.length > 0) {
                        document.querySelector('#AdmissionsGuideContainer .columns').removeAttribute("style");
                    }
                    if (document.querySelector('#AdmissionsGuideContainer .abandonment').style.length > 0) {
                        document.querySelector('#AdmissionsGuideContainer .abandonment').removeAttribute("style");
                    }
                    if (isMobileView || isTabletView) {
                        if(document.querySelector("#AdmissionsGuideContainer .nothanks"))
                            document.querySelector("#AdmissionsGuideContainer .nothanks").classList.add("hidden");
                        if(document.querySelector("#AdmissionsGuideContainer .thanksclose"))
                            document.querySelector("#AdmissionsGuideContainer .thanksclose").classList.add("hidden");
                        if(document.querySelector("#AdmissionsGuideContainer #AGblade-close-modal"))
                            document.querySelector("#AdmissionsGuideContainer #AGblade-close-modal").classList.add("hidden");
    
                    } 
                    else {
                        document.querySelector("#AdmissionsGuideContainer .btn-close").classList.add("hidden");;
                    }
                    if (!document.querySelector('#AdmissionsGuideContainer .mobileFormContainer').style.maxWidth){
                        if (document.querySelector("#AdmissionsGuideContainer .thanksclose")){
                            document.querySelector("#AdmissionsGuideContainer .thanksclose").classList.add("hidden");
                        }  
                    }
                }
            }
        }
        window.uopxDataLayer.track("PreprospectModalClose",{
            'componentName' : 'Zero Party',
            'name': formName, 
            "type": formType,
            "text": "Close Admissions Guide modal",
            "location": formLocation,
        });
    };

    keydownClose = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32){
            this.modalClose();
        }         
    }
      
    render() {
    //    const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = isMobileView ? (this.props.title || headerMobile) : (this.props.title || headerDesktop);
        let descriptionText = isMobileView ? (this.props.title || descriptionMobile) : (this.props.title || descriptionDesktop);
        let cta = this.props.cta || ctaText;
        let thankyouMessageTitle = isMobileView ?  (this.props.thankyoumessagetitle || thankyouMessageTitleMobile) : (this.props.thankyoumessagetitle || thankyouMessageTitleDesktop);
        let thankyouMessageDescription = isMobileView ? (this.props.thankyoumessagedescription || thankyouMessageDescriptionMobile) : (this.props.thankyoumessagedescription || thankyouMessageDescriptionDesktop);

        return (    
            <div>
            {/* <UOPHeader /> */}
                <section className="abandonment">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validateAdmissionsGuideForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form agParent"
                                                    aria-labelledby={isSubmit? "flex-div-AG-blade thanksdialogtitle-AGblade thanksdialogdesc-AGblade" : "flex-div-AG-blade dialogTitle-AGblade dialogDesc-AGblade"}
                                                    style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                    <Grid container >
                                                        { !isSubmit && isTabletView && 
                                                            <div 
                                                                // style={{paddingTop: '3rem'}}
                                                            >
                                                                {unescapeHTML(titleText)}
                                                                {unescapeHTML(descriptionText)}
                                                            </div>
                                                        } 
                                                {!isSubmit
                                                    ? (
                                                        <>  
                                                            
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="rfiabandonmentcopy" style={{ backgroundColor: '#F2F3F3' }}>
                                                                { !isTabletView && unescapeHTML(titleText)}
                                                                { !isTabletView && unescapeHTML(descriptionText)}
                                                                <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                                <Grid container >
                                                                    <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm modalagstack">
                                                                        <MaterialInput
                                                                            name="fullName"
                                                                            id="fullName-AGblade"
                                                                            label="First and Last name *"
                                                                            ariaRequired="true"
                                                                            ariaLabel="Enter your full name"
                                                                            variant="outlined"
                                                                            className='uopx-input modalfield'
                                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm modalagstack">
                                                                        <MaterialInput name="emailAddress" id="emailAddress-AGblade" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input modalfield' />
                                                                    </Grid>
                                                                    <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                                        <SelectField
                                                                            id="higherEducation"
                                                                            name="higherEducation"
                                                                            label="Highest degree earned"
                                                                            ariaLabel="Highest degree earned"
                                                                            data={this.state.highestLevelOfEducationlist}
                                                                            className="MuiFormControl-fullWidth"
                                                                            ariaRequired="false"
                                                                            formRef={this.ref.form}
                                                                            onChange={e => this.dropdownChange('higherEducation', e.target.value)}
                                                                        ></SelectField>
                                                                    </Grid>
                                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                                    <Button name='submit' size='large' type='submit' id="AGbladeModalSubmit" className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                                    { (isMobileView || isTabletView) && <Button size='small' variant = 'Secondary' color='secondary' onClick={this.modalClose} onKeyDown={this.keydownClose} id="AGbladeNoThanks" className='nothanks' style={{color:'#5e7079', margin:'auto', marginTop:'16px'}}> No thanks </Button> }
                                                                </Grid>
                                                            </Grid>
                                                            <ErrorFocus />
                                                        </>
                                                    ):(
                                                        <>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm modalthankscopy" style={{ backgroundColor: '#F2F3F3' }}>
                                                            <div className ="rfiabandonmentcopy" style={{ backgroundColor: '#F2F3F3' }} id="rfiAGbladecopy">
                                                                {unescapeHTML(thankyouMessageTitle)}
                                                                {unescapeHTML(thankyouMessageDescription)}
                                                                <div>
                                                                    <div>
                                                                        <Button size='large' id='downloadcta-AGblade' onClick={this.handleDownload} onKeyDown={this.keydownDownload} className='rfi-btn-submit btnclose' ariaLabel='Download now' aria-labelledby='downloadcta-AGblade'>Download now</Button>
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                            </Grid>
                                                        </> 
                                                    )}
                                                    {/*{ <button id="AGblade-close-modal" aria-label="Close dialog" title="Close" onClick={this.modalClose} onKeyDown={this.keydownClose} type="button" class="btn-close hidden"></button> }*/}
                                                    </Grid>
                                                </Form>
                                            )
                                        }} 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </div>
        );
    }
}

AdmissionsGuideForm.defaultProps = {
    classes: {},
};

AdmissionsGuideForm.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default AdmissionsGuideForm;