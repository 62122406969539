import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material/";
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { at } from 'lodash';
import { ErrorMessage, Field, useField } from "formik";
import '../styles/Input.scss';
import {makeid} from '../../CommonComponents';

//const MaterialInput = ({ name, label, errors, touched, isValidating}) => {

function MaterialInput(props) { 
  const { errorText, isInvalid, id, name, label, ariaLabel, ariaLabelledby, ariaRequired, role, type, required, isDisabled, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  let indexTab = '0';
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      indexTab = '-1';
      return error;
    }
  }
  let twochar = makeid(2);
  let newid = id +"-" + twochar;
  let conditionalAriaLabel = selectedValue?ariaLabel+" - "+selectedValue: ariaLabel;
  let newAriaLabelledby;
  if(ariaLabelledby && props.h2Flag) {
    newAriaLabelledby = `${ariaLabelledby} ${newid}`;
  } else {
    newAriaLabelledby = ariaLabelledby;
  }
  return (
    <>
    <Field
      disabled={isDisabled}
      name={name}
      as={TextField}
      label={label}
      id={newid}
      fullWidth
      required={required}
      variant="outlined"
      className="uopx-input"
      role={role}
      type={type}
      error={meta.touched && meta.error && true}
//      onChange={handleChange}
//      value={value}
      helperText={_renderHelperText()}
       InputProps={ !meta.error && { 
        endAdornment: ( 
            <InputAdornment position="end" tabIndex='0'>
              {meta.touched && !meta.error && <Done role='img' focusable="true" aria-hidden="false" aria-label='validation successful' color="secondary" title="validation successful"/>}
              {/*meta.touched && meta.error && <Close role='img' aria-label='validation failed' color="primary" /> */}
            </InputAdornment>
        ),
      }}
      inputProps={{'aria-required':ariaRequired, 'aria-label': conditionalAriaLabel, 'aria-labelledby': newAriaLabelledby}}
      {...rest}
    />
    </>
  );
 }
 

//};

export default MaterialInput;
