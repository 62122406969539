import React, { useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import MUIRadio from '@mui/material/Radio';
import { ErrorMessage, Field, useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { at } from 'lodash';
import { styled } from '@mui/styles';
import {makeid} from '../../CommonComponents';

const FormHelperTextWrap = styled(FormHelperText)({
  color: '#db3725',
  fontSize: '14px',
  backgroundColor: '#FFF',
  border: '1px solid #db3725',
  padding: '0.7rem 1.4rem',
});

const MaterialRadioGroup = styled(RadioGroup)({
  marginBottom: '0px !important',
});

const RadioButton = props => {
  const {
    children, name, className, checked, error, label, row,
  } = props;
  const [field, meta] = useField(props);
  function _renderHelperText() {
    const [touched] = at(meta, 'touched');
    // console.log('radio button error', error);
    if (touched && error && meta.error) {
      return <FormHelperTextWrap aria-live='assertive' className='Mui-error' id="formHelperTextWrap">{error}</FormHelperTextWrap>;
    //  return error;
    }
    return null;
  }

  return (
    <FormControl component="fieldset" className='uopx-radiogroup'>
      {/* legend */} 
      <legend className={className} name={name}>{label}</legend>
      {name !== "isScholarship" && <> 
        <MaterialRadioGroup
          row={row}
          name={name}
          aria-label={label}
          checked={checked}
          className={className}
          aria-required={true}
          role="radiogroup"
        >
          {children}
        </MaterialRadioGroup>
      </>} 
      {name === "isScholarship" && <> 
        <MaterialRadioGroup
          row={row}
          name={name}
          aria-label={label}
          checked={checked}
          className={className}
          role="radiogroup"
        >
          {children}
        </MaterialRadioGroup>
      </>}
      {_renderHelperText()}
    </FormControl>
  );
};

const MaterialRadio = ({
  items, name, className, onChange, row, value, props, label,
}) => {
  const [SelectedValue, setSelectedValue] = useState('');
  const handleChange = event => {
  //  console.log('event.target.value', event.target.value)
    setSelectedValue(event.target.value);
    onChange(event, props);
  };
  if (!SelectedValue && value) {
    setSelectedValue(value);
  }

  let twochar = makeid(2);

  return (
    <Field
      as={RadioButton}
      name={name}
      label={label}
      size="large"
      checked={SelectedValue}
      row={row}
      className={className}
      error={!SelectedValue && <ErrorMessage name={name} />}
    >
      {items.map((item, index) => (
        <FormControlLabel
          key={index}
          value={SelectedValue}   
          //aria-label={item.label}   
          control={(
            <MUIRadio  
              color="primary"
              size="medium"
              value={item.value}
              checked={SelectedValue === item.value}
              //aria-checked={SelectedValue === item.value}
              onChange={handleChange}
              name={name}
              //aria-label={item.label}
              //label={item.label}
              //id={item.label}
              // id={`${name}_${item.label}_${twochar}`}
              id={`${name}_${item.label}`}
              // ariaLabelledBy={`${name}_${item.label}`}
              // tabIndex={"0"}
              inputProps={{role:'radio', checked:(SelectedValue === item.value), 'aria-checked':(SelectedValue === item.value), 'aria-label':item.label}}
            />
            )}
          label={item.label}
        //  htmlFor={`${name}_${item.label}_${twochar}`}
          htmlFor={`${name}_${item.label}`}
          name={item.label}
        />
      ))}
    </Field>
  );
};

export default MaterialRadio;
