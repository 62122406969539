import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';;
import { Formik, Form } from "formik";
import date from 'date-and-time';
//import styles from '../layout/styles/MobileFormStyles';
//import styles from '../inline/SubscribeStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { unescapeHTML, feedDataToSessionStorage, loadLocalStorageData, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, mapAdditionalFields, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
//import checkmark from '../layout/images/checkmark.svg';
import { initValues, processJSONParameter } from '../common/configTools';
import { borderRadius } from 'polished';


const formRef = {};
let defaultTextHTML = '<h2 role="heading" aria-level="2" id="subscribeformheader" style="font-size:14px !important; font-weight: 700; line-height:20px; margin-bottom:10px;">Sign up for info, including our Admissions Handbook and ways to save.</h2>';
let defaultDescriptionTextHTML = '';
const initialEmailFormValues = {
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    userAgent: '',
    flex_EmailCaptureSource: 'STND',
    campaign: 'ValueExchange',
    source: 'PREP',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'By submitting this form, you consent to receive University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy-policy.html" style=font-weight:600;>Privacy Policy</a> for more information.',
    formName:'emailSignUp'
};  
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let zeroPartyFSData = {};

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite"); 
        el.setAttribute("aria-labelledby", "thanksmessage");
        el.setAttribute("opacity", "1");           
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
    }
}

class EmailSubscribeFooter extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initValues(initialValues, this.props);  
        loadLocalStorageData(initialValues);     
        this.setState({ initialValues });
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Non-RFI';
    	formName = 'Footer Newsletter form';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }    
    
    handleSubmit = async (values) => {
        if (document.activeElement.id === "FooterSubmit") {
            if (((values.fullName).trim().indexOf(' ')) > 0) {
                const i = (values.fullName.trim()).indexOf(' ');
                values.firstName = (values.fullName.trim()).substring(0, i); 
                values.lastName = (values.fullName.trim()).substring(i + 1);
            }
            this.setState({ initialValues: values });  
            window.uopSegment&&window.uopSegment.identify({'email': values.emailAddress});
            window.uopSegment&&window.uopSegment.track('FooterNewsletter', {'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
            //window.uopxDataLayer&&window.uopxDataLayer.track('FooterNewsletter', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'email': values.emailAddress}}});
            //this.setState({ isSubmit: true, currentStep:2 }); 
            //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
            mapAdditionalFields(values);
            feedDataToSessionStorage(values);
            values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
            Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

            let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
            if (window.location.host !== 'www.phoenix.edu') {
                postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
            }
            
            try {
                const response = await fetch(postURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
                });
            // console.log ("form submit values" , values);

            this.setState({
                initialValues: values,
                isSubmit: true,
                currentStep:2,
                message: null,
                // message: <div style={styles.success}>{responseJSON.message || 'No Message Given'}</div>,
            });
                //setTimeout(function(){trapFocus (document.querySelector('#modalrfi'), 0);}, 1000);
                //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
                fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType);
                srSpeak('email submitted');
                /* if (window.uopxDataLayer && window.uopxDataLayer.track) {
                    window.uopxDataLayer&&window.uopxDataLayer.track('ScholarshipBladeModalSubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Request my guide', 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign' : 'Scholarship Transfer Credits', 'campaignOption' : tcValue},},});
                } */
            } catch (e) {
            this.setState({
                message: <div className="">{e.message || e}</div>,
            });
            }
            if (window.FS) {
                zeroPartyFSData.source= "Email-Footer";
                //calling FS and passing the tracking data set
                window.FS.event('Submitted EDU_ZeroPartyData', zeroPartyFSData);
                //console.log("Submitted EDU_ZeroPartyData", zeroPartyFSData);
            }
        } 
    }
    
    validateFooterForm(values) {
        const footererrors = {};
        if (!values.fullName) {
            footererrors.fullName = 'First and Last name requires a value';
        } else if (values.fullName.trim().indexOf(' ') === -1) {
            footererrors.fullName = 'Please enter a First and Last name';
        } else if (!profanityCheck(values.fullName)) {
            footererrors.fullName = 'Full Name appears invalid';
        }
        if (!values.emailAddress) {
            footererrors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            footererrors.emailAddress = 'Email address appears invalid';
        }
        console.log('footererrors', footererrors, values);
        return footererrors;
    }

    // Not being used
    /*modalClose () {
    //    console.log ("inside modal close");
        let modal = document.querySelector(".modal");
        let overlay = document.querySelector(".overlay");
        if (modal !== null || overlay !== null){
            modal.classList.add("hidden");
            overlay.classList.add("hidden");
        }
        window.uopxDataLayer.track("NonRFIModalClose",{
            'componentName' : 'RequestInfo',
            'name': 'email Subscribe Step 2', // depends on step closing 
            "type": formType,
        }); 
    }; */
      
    render() {
        const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = this.props.description || defaultDescriptionTextHTML;
        let cta = this.props.cta || 'Sign Up';

        return (    
            <div className={classes.root}>
            {/* <UOPHeader /> */}
                <section className="footernewsletter">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
					    {/*	<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="newOneForm-SubTitle" style={{ fontSize: '18px', fontWeight: 700 }}>Email us &mdash; and we’ll get the ball rolling.</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   */}
                            <Grid item md={12} sm={12} lg={12} xs={12} style={{ maxWidth:'900px' }}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validateFooterForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    <Form className="mobile-form"
                                                aria-labelledby="subscribeformheader"
                                                style={{ position: 'relative', padding: '16px', backgroundColor:'#E8F9FA', border:'1px solid #B2BBBF', borderRadius: '4px' }} onChange={this.handleAnalyticsRFIFormStart}>
                                        <Grid container >
                                        {/*    <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm rfiabandonment">
                                                <div className=""> </div>
                                            </Grid> */}
                                    {!isSubmit
                                        ? (
                                            <>  
                                                <Grid item xs={12}>         
                                                    {unescapeHTML(titleText)}
                                                    {unescapeHTML(descriptionText)}
                                                    <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                    <MaterialInput
                                                        name="fullName"
                                                        id="fullName-Footer"
                                                        type="text"
                                                        label="First and Last name *"
                                                        ariaRequired="true"
                                                        aria-labelledby="subscribeformheader"
                                                        variant="outlined"
                                                        className='uopx-input footer'
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                    />
                                                    <MaterialInput name="emailAddress" id="emailAddress-Footer" label="Email address *" ariaRequired="true" aria-labelledby="subscribeformheader" variant="outlined" className='uopx-input footer'  onClick={this.handleAnalyticsRFIFormStart}/>
                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                    <Button name='submit' id="FooterSubmit" size='large' variant = 'outlined' color='secondary' type='submit' style={{height:'initial', backgroundColor:'#30718D', color: '#ffffff'}} className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                </Grid> 
												<ErrorFocus />
                                            </>
                                        ):(
                                            <>
                                                <Grid item xs={12} className ="margin-OneStepForm" id="thanksmessage" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h3 style={{ fontSize: '16px', fontWeight: 700, lineHeight: '24px', marginBottom:'16px'}}>Awesome - thanks </h3>
                                                    <div style={{ fontSize: '16px', lineHeight: '24px', marginBottom:'24px'}}>Good info is headed your way. Check your inbox soon.</div> 
                                                </Grid>
                                                {/* <div style={{ padding: '3rem'}}>
                                                    <Button size='large' onClick={this.modalClose} className='rfi-btn-submit'> Close </Button>
                                                </div> this.modalClose() */}
                                            </> 
                                          )}
                                          </Grid>
                                        </Form> 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        {/*    <Footer /> */}
            </div>
        );
    }
}

EmailSubscribeFooter.defaultProps = {
    classes: {},
};

EmailSubscribeFooter.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

//export default EmailSubscribeFooter;
//const EmailSubscribeFooterFormStyled = withStyles(styles)(EmailSubscribeFooter);

const generateClassName = createGenerateClassName({
    productionPrefix: 'emailFooter',
});

export default function (props) {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <EmailSubscribeFooter {...props} />
        </StylesProvider>
    );
}