import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function HorizontalLinearStepper(props) {
  const steps = props.steps;
  const step = props.step;
  const handleStep = (index) => () => {
    if(step !== index) {
        props.stepChange(index+1)
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            index===0?(
                <Step key={label} {...stepProps} className={`${props.stepClass} step${index}`} onClick={handleStep(index)} role="button" tabIndex="0" aria-labelledby={`step${index}Span1 step${index}Label`} aria-disabled={index===0?false:true}>
                    <span id={`step${index}Span1`} hidden>{`step ${index+1}, `}</span><StepLabel {...labelProps} id={`step${index}Label`} className={props.stepLabelClass}>{label}</StepLabel>
                </Step>
            ) : 
            (
                <Step key={label} {...stepProps} className={`${props.stepClass} step${index}`} onClick={handleStep(index)} role="button" tabIndex="-1" aria-labelledby={`step${index}Span1 step${index}Label`} aria-disabled={index===0?false:true}>
                    <span id={`step${index}Span1`} hidden>{`step ${index+1}, `}</span><StepLabel {...labelProps} id={`step${index}Label`} className={props.stepLabelClass}>{label}</StepLabel>
                </Step>
            )
          );
        })}
      </Stepper>
    </Box>
  );
}