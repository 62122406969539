import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues'
import Button from '@mui/material/Button';
import MaterialRadio from "../layout/MaterialComponents/MaterialRadio";
import MaterialRadioWithBox from "../layout/MaterialComponents/MaterialRadioWithBox";
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, processJSONParameter } from '../../components/common/configTools';
import getIPAddress, { loadCookiesData, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, fireRFISavingsOptionEvent, updatePrePopCookieValue, isUserEligible, scrollToTop, getDegreeLevel } from '../CommonComponents';
import { courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption, groupedCheckboxOption, higherEducation } from '../common/constants';
import ErrorFocus from '../common/ErrorFocus';
import { validateDynamic5Step } from '../layout/MobileFormValidation';
import UopHeader from '../layout/UopHeader';
import MobileFooter from '../layout/MobileFooter';
import UOPXButton from '../layout/MaterialComponents/button/Button';
import SelectField from '../UopxNativeSelectField';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import {dataLayer} from '../common/dataLayer';


let h1Tag1Step = "Let's get your questions answered. Start here.";
let h1Tag2Step = "You're on your way..."
let h1Tag3Step = "Good job. You're making progress.";
let h1Tag4step = 'Almost there - keep going!';
let h1Tag5Step = "Yay - last step! We'll connect soon to answer your questions.";
let waysToSaveValueArray = ["", "", "", ""]; // Sets placeholder values to ensure order of values
let itemsToBeSentToFormRef = []; // New array used to not mutate waysToSaveValueArray

const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};
const formRef = {};
const touched = {};
let prevDegree, prevFOS, prevDegreeLevel, prevCountry,prevCourseCategory, prevHighestEduLevel;
const disclaimerText = rfiFormInitialValues.disclaimer;
let scheduleCallFSData = {}

class MRfiDynamicFiveStep extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            activeStep: 1,
            totalSteps: 5,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/thank-you.html',
            formRef: null,
            isEligible: true,
            allChecked: false,
            checkboxList: [
                { id: 1, name: 'Scholarships', checked: false, label: 'Scholarships', ariaLabel: 'Scholarships', },
                { id: 2, name: 'Transfer_credits', checked: false, label: 'Transfer credits', ariaLabel: 'Transfer credits', },
                { id: 3, name: 'Work_life_credits', checked: false, label: 'Work & life credits', ariaLabel: 'Work & life credits', },
                { id: 4, name: 'Employer_tuition_discounts', checked: false, label: 'Employer tuition discounts', ariaLabel: 'Employer tuition discounts', }
            ],
            degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
            rfi_step: "rfi_step1"
        };
        this.state = this.initialState;
        //this.focusStep = React.createRef(); 
        this.h2Ref = React.createRef();
    }
    dropdownChange = (name, value) => {
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
        if (name === 'highestLevelOfEducation') {
            if ((value && prevHighestEduLevel !== value)  || value === '') {
                updatePrePopCookieValue('highestLevelOfEducation', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevHighestEduLevel = value;
                //initialValues.highestLevelOfEducation = value
                //validateOneStep(initialValues);
            }
        }
    }
    componentDidMount() {
        window.pagePath = "page-md_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Mobile`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI dynamic mobile - 5 step";
        getIPAddress(this.updateIPAddress);
        let initialValues = loadCookiesData(this.state.initialValues);
        if (document.referrer.indexOf('/cost-savings/ways-to-save/results')>0){
            h1Tag1Step = 'Share your information so we can talk about savings opportunities.';
            h1Tag2Step = 'Confirm which degree level you want to explore savings in.';
            h1Tag3Step = 'These details will help when we explore saving options.';
            h1Tag4step = 'Confirm the savings opportunities you want to explore with us.';
            h1Tag5Step = 'Share your contact info so we can chat soon.';
        } 
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        this.setState({ initialValues });
        if(prevFOS){
            console.log(prevFOS);
            this.resetDegreeLevel(prevFOS)
        }
	    const { activeStep } = this.state;
        formStep = activeStep;
        if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        } 
        //this.focusStep.current.focus();
        this.h2Ref.current.focus();
        dataLayer();
    }    

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: formRef.current.values
        });
        fireAnalyticsEvent('RFIStepBack', formName, formType);
        scrollToTop();
        //this.focusStep.current.focus();
        this.h2Ref.current.focus();
    };

    handleAnalyticsRFIFormStart = () => {
        if (this.state.rfi_begin !== true) {
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        const { initialValues } = this.state;
        if (name === 'fieldOfStudy') {
            formRef.current.values.fieldOfStudy = value;
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                this.resetDegreeLevel(value);
            }
        }
        if (name === 'degreeLevel') {
            formRef.current.values.degreeLevel = value;
            if (value && prevDegree !== value) {
                updatePrePopCookieValue('degreeLevel', value);
		        fireFOS_DlEvent(name, value, formName, formType);
                prevDegree = value;
                initialValues.degreeLevel = value;
            }
        }        
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        else if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
        else if (name === 'isCitizenOfUnitedStates') {
            formRef.current.values.isCitizenOfUnitedStates = value;
        }
        else if (name === 'isScholarship') {
            formRef.current.values.isScholarship = value;
        }
        this.setState({ initialValues });
        //    var isEligible = isUserEligible(formRef.current.values.countryOfResidence, formRef.current.values.isMilitary, formRef.current.values.isCitizenOfUnitedStates);
        //    this.setState({ isEligible: isEligible });
    };
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '')) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.values.degreeLevel='';
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }
    handleZipcodeChange = (zipcode, state) => {
        formRef.current.values.postalCode = zipcode;
        formRef.current.values.stateProvince = state;
        updatePrePopCookieValue('zipcode', zipcode);
        updatePrePopCookieValue('state', state);
    }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleCallback = (value, isChecked) => {// this function receives information from child checkbox component and updates the necessary formref values  
        value = typeof value.name !== "undefined" ? value.name : value;
        switch (value) {
            case 'scholarships':        
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(0, 1, value); // Replaces placeholder empty string if checkbox is checked
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(0, 1, ""); // Replaces value with empty string if checkbox is unchecked
                };
                break;
            case 'transfer_credits':             
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(1, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(1, 1, "");
                };
                break;
            case 'work_life_credits':            
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(2, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(2, 1, "");
                };
                break;
            case 'employer_tuition_discounts':         
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(3, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(3, 1, "");
                };
                break;
            case 'checkAll':
                if (isChecked) { // replaces all empty string values with respective value
                    waysToSaveValueArray.includes("scholarships") ? console.log("Value already in the array") : waysToSaveValueArray.splice(0, 1, "scholarships");
                    waysToSaveValueArray.includes("transfer_credits") ? console.log("Value already in the array") : waysToSaveValueArray.splice(1, 1, "transfer_credits");
                    waysToSaveValueArray.includes("work_life_credits") ? console.log("Value already in the array") : waysToSaveValueArray.splice(2, 1, "work_life_credits");
                    waysToSaveValueArray.includes("employer_tuition_discounts") ? console.log("Value already in the array") : waysToSaveValueArray.splice(3, 1, "employer_tuition_discounts");
                }
                else if (isChecked === false) {
                    waysToSaveValueArray = ["", "", "", ""]; // replaces all values with empty strings
                }
                break;
        }
        const filtered = waysToSaveValueArray.filter(function (values, index, arr) {
            return values !== ""; // Gets rid of any placeholder empty string values
        })
        itemsToBeSentToFormRef = filtered;
        formRef.current.values.waysToSave = itemsToBeSentToFormRef.join(", "); // Sends filtered values to formref to be sent down stream
        fireRFISavingsOptionEvent(formRef.current.values.waysToSave,  formName, formType);
    }

    isCBEConsent = (event, checked) => {
        console.log(event); 
        console.log(checked)
        formRef.current.values.isCBE = checked;     
    }
    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    handleSubmit = (values) => {
        const { activeStep, totalSteps } = this.state;
        if (formRef.current.touched['zipcode'] || formRef.current.touched['postalCode'] || touched['zipcode']) {
            formRef.current.touched['zipcode'] = undefined;
            formRef.current.touched['postalCode'] = undefined;
            touched['zipcode'] = undefined;
        }
        this.setState({ initialValues: values });
        if (activeStep === totalSteps && document.activeElement.name === "submit") {
            values.flex_Info_Requested__c = values.waysToSave;
            /* if (values.fieldOfStudy !== 'nondegree' && (values.degreeLevel !== 'doctoral' && values.degreeLevel !== 'nondegree'))  {
                values.flex_RFI_Callback__c = 'rfiCallback-control';
            } */
            this.setState({ initialValues: values });
            //submitForm(values);
            submitForm(values, this.props);
            if (window.FS) {
                if (values.fieldOfStudy !== 'nondegree' && (values.degreeLevel !== 'doctoral' && values.degreeLevel !== 'nondegree'))  {
                    scheduleCallFSData.fos= values.fieldOfStudy;
                    scheduleCallFSData.degreeLevel= values.degreeLevel;
                    scheduleCallFSData.rfiCallback=values.flex_RFI_Callback__c;
                    //calling FS and passing the tracking data set
                    window.FS.event('Submitted M_RFI_ScheduleCall', scheduleCallFSData);
                }
            }
        }
        else if (document.activeElement.name === "next"){
            fireAnalyticsEvent('RFIStep'+ (activeStep + 1), formName, formType);
            if (activeStep <= totalSteps - 1)
                this.setState({ activeStep: activeStep + 1 });
	        
            formStep = activeStep + 1;
            scrollToTop();
            //this.focusStep.current.focus();
            this.h2Ref.current.focus();
        }
    }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
            totalSteps,
        } = this.state;

        return (<>
            <UopHeader />
            <main className="rfi">
                <Grid container justifyContent='center' className={"mobileFormContainer international-student-rfi step" + activeStep}>
                    <Grid item md={8} sm={8} lg={8} xs={12}>
                        <Typography
                            variant='body2'
                            component='h2'
                            className='mainHeader'
                            style={{ background: 'linear-gradient(90deg, #e3f5fa 3%, #92c8d6 100% )', margin: '-24px -24px 24px', padding: '24px', fontSize:'21px' }}
                            ref={this.h2Ref}
                            tabIndex={0}
                        >
                            {activeStep === 1 ? h1Tag1Step :
                                activeStep === 2 ? h1Tag2Step :
                                    activeStep === 3 ? h1Tag3Step :
                                        activeStep === 4 ? h1Tag4step :
                                        h1Tag5Step}
                        </Typography>
                        <Grid item md={12} sm={12} lg={7} xs={12} className="stepCounter">
                            <Typography
                                variant='body2'
                                component='div'
                                className="stepCounter"
                                tabIndex={-1}
                                /* ref={this.focusStep} */
                                step={activeStep}
                            >
                                <span aria-label={`Step ${activeStep} of ${totalSteps}`}>Step <span className="activeStepCount">{activeStep}</span> of <span className="totalStepsCount">{totalSteps}</span></span>
                            </Typography>
                        </Grid>
                        {activeStep !== 4 && 
                            <Typography
                                variant='body2'
                                component='div'
                                className="stepSubHeader req-info"
                                style={{ fontSize: "1.6rem", color: "#001823" }}
                            >
                                * Required information
                            </Typography>
                        }

                        <React.Fragment>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                {activeStep === 1 &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            if (!(props.values.fieldOfStudy === 'nursing' || props.values.fieldOfStudy === 'healthcare')) {
                                                props.values.isRegisteredNurse = '';
                                            }
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                    onChange={this.handleAnalyticsRFIFormStart}
                                                    autoComplete="on"
                                                >
                                                    <div className={"step" + activeStep}>
                                                        <MaterialRadioWithBox value={formRef?.current?.values.fieldOfStudy} className="selectRadioBoxLabel-bold" onChange={this.handleChange} items={dynamicFieldOfStudyDropdownVariant} label="Which area of study are you interested in? *" name='fieldOfStudy' ariaLabel="Which area of study are you interested in?"></MaterialRadioWithBox>
                                                        {conditionalRadio(props.values.fieldOfStudy) && <>
                                                            <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} className="selectRadioLabel-bold" row={true} onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                        </>}
                                                    </div>
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next1"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )                                            
                                        }}
                                    </Formik>
                                }
                                {activeStep === 2 && formRef?.current?.values.fieldOfStudy === 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep, false)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                >
                                                   <SelectField
                                                    id="flex_Course_Category__c"
                                                    name="flex_Course_Category__c"
                                                    label="Course category *"
                                                    data={courseCategoryDropdown}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                    ></SelectField>                                                
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 2 && formRef?.current?.values.fieldOfStudy !== 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep, true)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                >
                                                    <MaterialRadioWithBox value={formRef?.current?.values.degreeLevel} className="selectRadioBoxLabel-bold" onChange={this.handleChange} items={this.state.degreeLevel} label="Which degree level are you interested in? *" name='degreeLevel' ariaLabel="Which degree level are you interested in?"></MaterialRadioWithBox>
                                                    {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                        <div className='cbe'>
                                                            <SingleCheckbox
                                                                className="cbe-checkbox"
                                                                id="isCBE"
                                                                label={unescapeHTML(this.getCBEText())}
                                                                ariaLabel={unescapeHTML(this.getCBEText())}
                                                                checkboxName="isCBE"
                                                                parentCallback={this.isCBEConsent}
                                                                defaultValue={formRef.current.values.isCBE}  
                                                            />
                                                        </div>
                                                    </>}
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 3 &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form" style={{ position: 'relative' }}
                                                    aria-label="Request For Information">  
                                                    <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" ariaLabel="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef} required></ZipCode>
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 4 && (formRef?.current?.values.degreeLevel === 'nondegree' || formRef?.current?.values.fieldOfStudy === 'nondegree') &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information">
                                                    <SingleCheckbox
                                                        id="isEmployer"
                                                        className='ways2save-checkbox'
                                                        label="Employer tuition discounts"
                                                        ariaLabel="Employer tuition discounts"
                                                        checkboxName="employer_tuition_discounts"
                                                        parentCallback={this.handleCallback}
                                                        legend="I want to explore this time and money-saving option:"
                                                        defaultValue={waysToSaveValueArray.includes("employer_tuition_discounts")} 
                                                    />
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 4 && formRef?.current?.values.degreeLevel !== 'nondegree' && formRef?.current?.values.fieldOfStudy !== 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information">
                                                    <SingleCheckbox
                                                        className='ways2save-checkbox'
                                                        label="All of the above + more ways to save"
                                                        ariaLabel="All of the above + more ways to save"
                                                        checkboxName="checkAll"
                                                        parentCallback={this.handleCallback}
                                                        legend="I want to explore these time and money-saving options:"
                                                        options={groupedCheckboxOption}
                                                        defaultValueGroup={waysToSaveValueArray}
                                                        defaultValue={waysToSaveValueArray.filter((value) => value !== "").length == 4}
                                                    />
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 5 && <>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => validateDynamic5Step(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form" style={{ position: 'relative' }}
                                                    aria-label="Request For Information">
                                                    <MaterialInput name="firstName" id="firstName"
                                                        label="First name *" ariaRequired="true" ariaLabel="Enter First name" />
                                                    <MaterialInput name="lastName" id="lastName"
                                                        label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                    <MaterialPhoneNumber id="phoneNumber" name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter Phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className='customLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
                                                    <div className="disclaimer">
                                                        {unescapeHTML(disclaimerText)}
                                                    </div>
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='submit'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-submit"
                                                        >
                                                            Request information
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </>
                                }
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </Grid>
                {/* </Grid> */}
            </main>
            <div className="mobile-footer2">
                <p>See our <a href="http://www.phoenix.edu/copyright-legal/privacy_policy.html"
                    rel="noopener noreferrer"
                    target="_blank">Privacy Policy.</a></p>
                <p>© {new Date().getFullYear()} University of Phoenix, Inc. All rights reserved.</p>
            </div>
            <MobileFooter theme="hidden" />
        </>);
    }

}

MRfiDynamicFiveStep.defaultProps = {
    classes: {},
};

MRfiDynamicFiveStep.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(MRfiDynamicFiveStep);
