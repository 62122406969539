import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Box from '@mui/material/Box';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import styles from '../layout/styles/InlineFormStyles';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, addDropDownOption, processJSONParameter, initValues } from '../common/configTools';
import getIPAddress, {
    getDegreeLevel,
    conditionalCBE,
    loadCookiesData,
    profanityCheck,
    submitForm,
    updatePrePopCookieValue,
    fireAnalyticsEvent, fireFOS_DlEvent, srSpeak,
} from '../CommonComponents';
import { degreeLevelVariant as degreeLevelOptions, dynamicFieldOfStudyDropdownVariant as fieldOfStudyOptions, radioOption, courseCategoryDropdown, higherEducation } from '../common/constants';
import Button from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';


let prevFOS, prevDegreeLevel, prevState, prevCourseCategory, prevHighestEduLevel;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;
let scrollOffSetStepper = -100;
let scrollOffSetErrors = -250;
let formStep;
let formType;
let formName;
let formPageType;
let tabIndexStep2 = -1;

// Allow unescaped HTML
class DynamicRFIInlineForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/thank-you.html',
            showIntlText: 'US',
            currentStep: 1,
            fieldOfStudyOptions: processJSONParameter(props.fieldOfStudyOptions || [...fieldOfStudyOptions]),
            degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
            initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
            courseCategory: processJSONParameter([...courseCategoryDropdown]),
            higherEducationlist: processJSONParameter([...higherEducation])
        };
        this.ref = {
            container: React.createRef(),
            form: React.createRef(),
        };
        this.stepperRef = React.createRef();
    }
    componentDidMount() {
        let { initialValues } = this.state;
        initialValues = loadCookiesData(initialValues);
        //this.initValues(initialValues);
        initValues(initialValues, this.props);
        addDropDownOption(initialValues.fieldOfStudy, this.state.fieldOfStudyOptions);
        addDropDownOption(initialValues.degreeLevel, this.state.degreeLevel);
        this.setState({ initialValues });
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        if (prevFOS && this.props.theme === 'twostep') {
            this.resetDegreeLevel(prevFOS)
        };
        getIPAddress(this.updateIPAddress).then();
        this.handleZipCodeChange(initialValues.postalCode, initialValues.stateProvince);
        const { currentStep } = this.state;
        formPageType = "RFI";
        formStep = currentStep;
        if (window.uopxDataLayer) {
            formType = "RFI";
            switch (this.props.theme) {
                case 'twostep':
                    formName = 'RFI inline - 2 step';
                    break;
                default:
                    formName = 'RFI inline - 1 step';
                    break;
            }
        }

        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }

        const onScroll = () => {
            const footerContainerElm = document.querySelector('.sticky-footer-container');
            const formContainerElm = this.ref.container.current;
            //console.log ("formContainerElm ", formContainerElm);
            if (!footerContainerElm) {
                return;
            }
            const guideContainerTop = formContainerElm.offsetTop;
            const guideContainerBottom = formContainerElm.offsetTop
                + formContainerElm.offsetHeight;
            const screenTop = window.scrollY;
            const screenBottom = window.scrollY + window.innerHeight;
            const isMobileView = window.innerWidth <= 640;
            //console.log ("guideContainerTop and guideContainerBottom ", guideContainerTop, guideContainerBottom, formContainerElm.offsetTop, formContainerElm.offsetHeight);

            const isFormNotInTheView = guideContainerTop > screenBottom || guideContainerBottom < screenTop;
            //console.log ("isFormNotInTheView ", isFormNotInTheView);

            if (isMobileView && isFormNotInTheView) {
                footerContainerElm.setAttribute('style', 'display: block !important');
            } else {
                footerContainerElm.setAttribute('style', 'display: none !important');
            }
        };
        document.addEventListener('scroll', onScroll);
        setTimeout(onScroll, 100);
        if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        //this.scrollToElement(document.getElementsByClassName('scrollForm')[0], scrollOffSetStepper);
    }
    isFieldEnabled(fieldName) {
        if (this.props[`${fieldName}Enabled`]) return true;
        let { fieldsEnabled } = this.props;
        switch (this.props.theme) {
            default:
                break;
            case 'twostep':
                // Default two-step form with scholarship question
                if (!fieldsEnabled) fieldsEnabled = 'isScholarship';
                break;
        }
        return fieldsEnabled && (fieldsEnabled.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
    }

    isFieldHidden(fieldName) {
        const { fieldsHidden } = this.props;
        return fieldsHidden && (fieldsHidden.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
    }

    getTitleText() {
        let defaultTextHTML;
        const defaultIntlTextTitle = "<a class='btn-right-arrow intlText' style='font-size:16px;' href='https://www.phoenix.edu/request/international-student'>International students start here <span class='right-arrow'></span></a>";
        switch (this.props.theme) {
            default:
                defaultTextHTML = "<h2 class='title-small' style='text-align:left;'>Let's explore ways to save on your education.</h2>";
                break;
            case 'twostep':
                defaultTextHTML = "<h2 class='title-small' style='text-align:left;'>Let's explore ways to save on your education.</h2>";
                break;
        }
        let titleText = this.props.title || defaultTextHTML;
        const showIntlText = localStorage.getItem('country');
                if (this.state.showIntlText !== showIntlText) {
                    titleText += this.props.intlTitle || defaultIntlTextTitle;
                }
        return titleText;
    }

    getDescriptionText() {
        let descriptionText = this.props.description || '';
        return descriptionText;

        }

    dropdownChange = (name, value) => {
        // Update the component state
        const { initialValues } = this.state;
        //initialValues[name] = value;        
        //this.setState({ initialValues });
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
                /*if (this.props.theme === 'twostep' && value !== 'nondegree') {
                    this.resetDegreeLevel(value);                                    
                }*/     
            }
        }
        if (name === 'degreeLevel') {
            //this.ref.form.current.touched.degreeLevel = true;
            if ((value && prevDegreeLevel !== value)) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value;
                this.validate2Step(initialValues, this.state.currentStep);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
        if (name === 'highestLevelOfEducation') {
            if (value && prevHighestEduLevel !== value) {
                updatePrePopCookieValue('highestLevelOfEducation', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevHighestEduLevel = value;
                initialValues.highestLevelOfEducation = value
                //validateOneStep(initialValues);
            }
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    changeStep = (step, e)=> {
        if (step === 2) {
            this.handleNextButton();
            return;
        }
        this.setState({ currentStep: step });
        fireAnalyticsEvent('RFIStep' + step, formName, formType);
        formStep = this.state.currentStep;
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: this.ref.form.current.values
        });
        fireAnalyticsEvent('RFIStepBack', formName, formType);
        scrollToTop();
        this.focusStep.current.focus();
        this.scrollToElement(this.stepperRef.current);
        this.stepperRef.current.focus({preventScroll: true});
        
    };

    handleChange = e => {
        const { name, value, checked } = e.target;
        switch (name) {
            case 'isMilitary':
                this.ref.form.current.values.isMilitary = value;
                break;
            case 'isRegisteredNurse':
                this.ref.form.current.values.isRegisteredNurse = value;
                this.setState({ degreeLevel: getDegreeLevel(this.state.initialDegreeLevel, this.state.initialValues.fieldOfStudy, value) });
                break;
            case 'isScholarship':
                this.ref.form.current.values.isScholarship = value;
                break; 
            case 'isCBE':
                this.ref.form.current.values.isCBE = checked;
                break;
            default:
                break;
        }
    };

    handleAnalyticsRFIFormStart = () => {
        if (this.state.rfi_begin !== true) {
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel, fosValue, this.ref.form.current.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        //if ((this.ref.form.current.touched['degreeLevel'] === true)) {
        if ((this.ref.form.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
            var optionExists = newDegreeLevelOptions.some((e) => {this.state.initialValues.degreeLevel === e.value;});
            //if prev degree level doesnt exist, reset
            if (!optionExists) this.ref.form.current.setFieldValue('degreeLevel', '')
        }
        this.setState({ degreeLevel: newDegreeLevelOptions }); // EX: Bachelors, Individual Courses
    }

    handleZipCodeChange = (zipcode, state) => {
        this.ref.form.current.values.postalCode = zipcode;
        this.ref.form.current.values.stateProvince = state;
        updatePrePopCookieValue('zipcode', zipcode);
        updatePrePopCookieValue('state', state);

        if (state && prevState !== state) {  
            prevState = state;
        }
        this.updateAndNotify(state);
    }

    updateAndNotify = state => {
        this.setState({ stateProvince: state });
    }

    handlePhoneNumberBlur = () => {
        // this.ref.form.current.values.phoneNumber = value;
    }
    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }
    isCBEConsent = (event, checked) => {
        //const { checked } = event.target;
        //this.ref.form.current.values.isCBE = checked;
        this.ref.form.current.values.isCBE = checked;
    }

    handleNextButton = () => {
        const values = { ...this.state.initialValues, ...this.ref.form?.current?.values };    
        const errors = this.validate2Step(values, this.state.currentStep);
        this.ref.form.current.setStatus(errors);
        if (Object.values(errors).length === 0
            && (this.props.theme === 'twostep')
            && this.state.currentStep === 1) {
                document.querySelector(".inlineStep2").setAttribute("aria-disabled", false);
                tabIndexStep2 = 0; 
                this.setState({ currentStep: 2, initialValues: values });
                fireAnalyticsEvent('RFIStep2', formName, formType);
                formStep = this.state.currentStep;
                this.scrollToElement(document.getElementsByClassName('scrollForm')[0], scrollOffSetStepper); 
                setTimeout(function(){srSpeak('contact information', 'assertive'); document.querySelector("#inlineRFIstep2").focus();}, 300);  
        } else {
            this.ref.form.current.setTouched(errors);
        }
    }

    handleSubmit = formValues => {
        const values = { ...this.state.initialValues, ...formValues };        
        if(values.fieldOfStudy === 'nondegree'){
            values.degreeLevel = '';
        } else if (values.fieldOfStudy !== 'nondegree') {            
            values.flex_Course_Category__c = '';
        }
        console.log(values);
        const errors = this.validate2Step(values, this.state.currentStep);
        this.ref.form.current.setStatus(errors);
        if ((this.props.theme === 'twostep')
            && this.state.currentStep === 1 && document.activeElement.name === "next") {                 
                    fireAnalyticsEvent('RFIStep2', formName, formType);
                    this.setState({ currentStep: 2, initialValues: values });
                    formStep = this.state.currentStep;
                    this.scrollToElement(document.getElementsByClassName('scrollForm')[0], scrollOffSetStepper); 
        } else {
            if (((values.fullName).trim().indexOf(' ')) > 0) {
                const i = (values.fullName.trim()).indexOf(' ');
                values.firstName = (values.fullName.trim()).substring(0, i); 
                values.lastName = (values.fullName.trim()).substring(i + 1);
            }
            if (document.activeElement.id === "inlineRfiSubmit") {
                this.setState({ initialValues: values });
                //    values = mapAdditionalFields(values);
                //submitForm(values);
                submitForm(values, this.props);
                this.scrollToElement(this.stepperRef.current);
                this.stepperRef.current.focus({preventScroll: true});
            }
        }
    }

    scrollToElement(ele, yOffset = scrollOffSetStepper) { // USE DEFAULT VALUE FOR STEPPER
        const y = ele.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    focusFirstErroredField(step, errors, values) {
        let fields = []
        switch(step) {
            case 1:
                fields = ['fieldOfStudy', 'degreeLevel', 'zipcode'];
                if(this.isRegisteredNurseOptionVisible()) {
                    fields = ['fieldOfStudy', 'isRegisteredNurse', 'degreeLevel', 'zipcode'];
                }
                if(values?.fieldOfStudy === 'nondegree') {
                    fields = ['fieldOfStudy', 'flex_Course_Category__c', 'zipcode'];
                }
                break;
            case 2:
                fields = ['fullName', 'emailAddress', 'phoneNumber', 'isMilitary'];
                break;
            default:
                break;
        }

        for(let i=0; i<fields.length; i++) {
            let field = fields[i];
            if(errors[field]) {
                const ele = document.getElementsByName(field)[0]; // CAN CAUSE TROUBLE IF MULTIPLE FORMS ARE THERE;
                this.scrollToElement(ele, scrollOffSetErrors);
                ele.focus({preventScroll: true});
                break;
            }
        }
    }

    isRegisteredNurseOptionVisible() {
        const { initialValues } = this.state;
        return [
            'healthcare',
            'nursing',
        ].indexOf(initialValues.fieldOfStudy) !== -1;
    }

    isMilitaryOptionVisible() {
        const { initialValues } = this.state;
        return !!initialValues.fieldOfStudy;
        // return [
        //     'technology'
        // ].indexOf(this.state.fieldOfStudy) !== -1
    }

    validate2Step(values, step) {
        const errors = {};
        switch (step) {
          case 1:      
            if (!values.fieldOfStudy) {
                errors.fieldOfStudy = 'Area of study requires a value';
            }
            if (values.fieldOfStudy !== 'nondegree'){
                if (!values.degreeLevel) {
                    errors.degreeLevel = 'Degree level requires a value';
                }
            }
            if (values.fieldOfStudy === 'nondegree'){
                if (!values.flex_Course_Category__c){
                    errors.flex_Course_Category__c = 'Course category requires a value';
                }
            }
            if (this.isRegisteredNurseOptionVisible() && !values.isRegisteredNurse) {
                errors.isRegisteredNurse = 'Registered nurse requires a value';
            }
            if (!values.postalCode) {
                errors.zipcode = 'Enter a ZIP code';
            }
            if (values.postalCode && values.postalCode.length !== 5) {
                errors.zipcode = 'ZIP codes must include 5 numeric characters';
            }
            if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
                errors.zipcode = 'ZIP code must be numeric';
            } else if (values.postalCode && values.stateProvince === 'N/A') {
                errors.zipcode = 'ZIP code appears invalid';
            }
            if (values.flex_Community_College_University__c) {
                const check = profanityCheck(values.flex_Community_College_University__c);
                if (check === false) {
                    errors.flex_Community_College_University__c = 'Education appears invalid';
                }
            }
            break;
          case 2:
            if (!values.fullName) {
                errors.fullName = 'First and last name requires a value';
            } else if (values.fullName.trim().indexOf(' ') === -1) {
                errors.fullName = 'Please enter a First and last name';
            }
            if (values.fullName) {
                const check = profanityCheck(values.fullName);
                if (check === false) {
                    errors.fullName = 'Name appears invalid';
                }
            }
            if (!values.emailAddress) {
              errors.emailAddress = 'Email address requires a value';
            }
            if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              errors.emailAddress = 'Email address appears invalid';
            } 
            if (!values.phoneNumber) {
                errors.phoneNumber = 'Phone number requires a value';
            }
            if (values.phoneNumber && values.phoneNumber.length !== 10) {
                errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            }
            if (values.isMilitary === '' || values.isMilitary === undefined) {
                errors.isMilitary = 'U.S. Military affiliation requires a value';
            }
            break;
          default:
            break;
        }
        if(document.activeElement.name === 'next' || document.activeElement.name === 'submit') {
            this.focusFirstErroredField(step, errors, values);
        }
        return errors;
    }

    render() {
        const { classes } = this.props;
        const {
            initialValues,
        } = this.state;

        return (
            <div id="LPinlineRFI" className="inlineFormContainer">
                <section
                    aria-label="Request For Information"
                    className={`${classes.root} businessGuideInlineFormGrid`}
                >
                    <Box>
                        <Grid container  ref={this.ref.container}>
                            <Grid item xs={12}>
                                <div className="scrollForm"></div>
                                <div className={classes.inlineFormTitle} style={{ textAlign: 'center'}}>{unescapeHTML(this.getTitleText())}</div>
                                {unescapeHTML(this.getDescriptionText())}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={values => this.validate2Step(values, this.state.currentStep)}
                                        innerRef={form => this.ref.form.current = form}
                                    >
                                        {() => this.renderForm()}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Box>
                </section>
                {/*    <Footer />  */}
            </div>
        );
    }

    renderForm() {
        return (
            <Form
                className="mobile-form fix-notched-outline"
                aria-label="Request For Information"
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
            >
                {this.renderFormContent()}
                {/*<FormikErrorFocus
                    offset={-50}
                    align="top"
                    focusDelay={200}
                    ease="linear"
                    duration={1000}
                />*/}
            </Form>
        );
    }

    renderFormContent() {
        const { theme } = this.props;
        const { classes } = this.props;
        switch (theme) {
            default:
                return (
                    <Grid container className="MuiOutlinedInput-noOutline">
                        {this.renderRequiredInformation()}
                        {this.renderSelectFieldOfStudy()}
                        {this.renderIsRegisteredNurseOption()}
                        {this.renderSelectDegreeLevel()}
                        {this.renderTransferCredit()}
                        {this.renderInputFullName()}
                        {this.renderInputEmail()}
                        {this.renderInputPhoneNumber()}
                        {this.renderInputZipCode()}
                        {this.renderInputEducation()}
                        {/* this.renderOptionHasCommunicationConsent() */}
                        {this.renderOptionIsMilitary()}
                        {this.renderCheckboxIsCBE()}
                        {/* this.renderCheckboxHasStateConsent() */}
                        <div className="legal-text">
                            {this.renderLegalText()}
                            {this.renderSubmitButton()}
                        </div>
                    </Grid>
                );
            case 'twostep':
                if (this.state.currentStep === 1) {
                    if(this.state.initialValues.fieldOfStudy === 'nondegree'){
                        return (
                            <Grid container className="MuiOutlinedInput-noOutline">
                                {this.renderStepFields()}
                                {this.renderRequiredInformation()}
                                {this.renderSelectFieldOfStudy()}
                                {this.renderIsRegisteredNurseOption()} 
                                {this.renderCourseCategory()}
                                {this.renderTransferCredit()}                                                   
                                {this.renderInputZipCode()}
                                {this.renderInputEducation()}                            
                                {this.renderCheckboxIsCBE()}
                                <div className="legal-text">
                                    {this.renderNextButton()}
                                </div>
                            </Grid>
                        );
                    }
                    else {
                        return (
                            <Grid container className="MuiOutlinedInput-noOutline">
                                {this.renderStepFields()}
                                {this.renderRequiredInformation()}
                                {this.renderSelectFieldOfStudy()}
                                {this.renderIsRegisteredNurseOption()}                    
                                {this.renderSelectDegreeLevel()}
                                {this.renderTransferCredit()}
                                {this.renderInputZipCode()}
                                {this.renderInputEducation()}
                                {/* this.renderOptionHasCommunicationConsent() */}
                                {this.renderCheckboxIsCBE()}
                                <div className="legal-text">
                                    {this.renderNextButton()}
                                </div>
                            </Grid>
                        );   
                    }
                }
                return (
                    <Grid container className="MuiOutlinedInput-noOutline">
                        {this.renderStepFields()}
                        {this.renderRequiredInformation()}
                        {this.renderInputFullName()}
                        {this.renderInputEmail()}
                        {this.renderInputPhoneNumber()}
                        {this.renderOptionIsMilitary()}
                        {/*this.renderCheckboxHasStateConsent()*/}
                        <div className="legal-text">
                            {this.renderLegalText()}
                            {this.renderSubmitButton()}
                            <br/><button style={{background:'inherit', border:'none', cursor:'pointer', paddingTop:'18px'}} className={'redesign-link'} aria-label="Back to Area of Interest" tabIndex='0' onClick={() => {this.setState({ currentStep: 1 }); setTimeout(function(){document.querySelector('#fieldOfStudy').focus()}, 300);}}>
                            <span class="tertiary-left-arrow" style={{bottom:'unset'}}></span><span class="tertiary-word">Back </span>
                            </button>
                        </div>
                    </Grid>
                );
        }
    }

    renderStepFields() {
        const { classes } = this.props;
        const { currentStep } = this.state;
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <div className={classes.stepSelectionContainer}>
                    <div
                        id="inlineRFIstep1"
                        className={classes.stepSelection + " inlineStep1"}
                        tabIndex={0}
                        onClick={(e) => this.changeStep(1, e)}
                        onKeyDown={(e) => {if (e.keyCode === 13 || e.keyCode === 32)
                            this.changeStep(1, e);
                        }}
                        role="button"
                    >
                        <span
                            className={classes.navCircle + (currentStep === 1 ? ' active' : '')}
                        >
                            1
                        </span>
                        <span className={classes.stepSelectionText}>Area of Interest</span>
                    </div>
                    <div
                        id="inlineRFIstep2"
                        className={classes.stepSelection + " inlineStep2"}
                        tabIndex={(tabIndexStep2)}
                        onClick={(e) => this.changeStep(2, e)}
                        onKeyDown={(e) => {if (e.keyCode === 13 || e.keyCode === 32)
                            this.changeStep(2, e);
                        }}
                        role="button"
                        aria-disabled="true"
                    >
                        <span
                            className={classes.navCircle + (currentStep === 2 ? ' active' : '')}
                        >
                            2
                        </span>
                        <span className={classes.stepSelectionText}>Contact Info</span>
                    </div>
                </div>
            </Grid>
        );
    }

    renderSelectFieldOfStudy() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('fieldOfStudy')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    label="Area of study *"
                    ariaLabel="select Area of study option"
                    data={this.state.fieldOfStudyOptions}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('fieldOfStudy', e.target.value)}
                />
            </Grid>
        );
    }

    renderIsRegisteredNurseOption() {
        const classes = ['selectRadioLabel MuiFormControl-fGWhite margin-InlineForm '];
        if (this.isFieldHidden('isRegisteredNurse')) classes.push('hidden');
        return this.isRegisteredNurseOptionVisible() ? (
            <Grid item xs={12} className={classes.join(' ')}>
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isRegisteredNurse}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Are you currently a registered nurse? *"
                    name="isRegisteredNurse"
                    ariaLabel="Are you currently a Registered Nurse?"
                />
            </Grid>
        ) : null;
    }

    renderSelectDegreeLevel() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('degreeLevel')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="degreeLevel"
                    name="degreeLevel"
                    label="Degree level *"
                    ariaLabel="select Degree level option"
                    data={this.state.degreeLevel}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('degreeLevel', e.target.value)}
                />
            </Grid>
        );
    }

    renderTransferCredit() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <SelectField
                    id="highestLevelOfEducation"
                    name="highestLevelOfEducation"
                    label="Highest degree earned"
                    ariaLabel="Highest degree earned"
                    data={this.state.higherEducationlist}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="false"
                    formRef={this.ref.form}
                    onChange={e => this.dropdownChange('highestLevelOfEducation', e.target.value)}
                />
            </Grid>
        );
    }

    renderCourseCategory() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('flex_Course_Category__c')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="flex_Course_Category__c"
                    name="flex_Course_Category__c"
                    label="Course category *"
                    ariaLabel="select course category option"
                    data={this.state.courseCategory}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('flex_Course_Category__c', e.target.value)}
                />
            </Grid>
        );
    }

    renderInputFullName() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <MaterialInput
                    name="fullName"
                    id="fullName"
                    type="text"
                    label="First and last name *"
                    ariaRequired="true"
                    ariaLabel="Enter first and last name"
                />
            </Grid>
        );
    }

    renderInputEmail() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <MaterialInput
                    name="emailAddress"
                    id="emailAddress"
                    type="email"
                    label="Email address *"
                    ariaRequired="true"
                    ariaLabel="Enter Email address"
                    variant="outlined"
                />
            </Grid>
        );
    }

    renderInputPhoneNumber() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <MaterialPhoneNumber
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone number *"
                    ariaRequired="true"
                    ariaLabel="Enter phone number"
                    handleBlur={this.handlePhoneNumberBlur}
                />
            </Grid>
        );
    }

    renderInputZipCode() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <ZipCode
                    value={this.ref.form?.current?.values.postalCode}
                    type="zipcode"
                    id="zipcode"
                    label="ZIP code *"
                    ariaLabel="Enter zip code"
                    ariaRequired="true"
                    handleChange={this.handleZipCodeChange}
                    formRef={this.ref.form}
                />
            </Grid>
        );
    }

    renderInputEducation() {
        if (!this.isFieldEnabled('education')) return null;
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <MaterialInput
                    name="flex_Community_College_University__c"
                    id="flex_Community_College_University__c"
                    type="text"
                    label="College or university"
                    ariaRequired="false"
                    ariaLabel="Enter a college or university name"
                />
            </Grid>
        );
    }

     renderOptionHasCommunicationConsent() {
        if (!this.isFieldEnabled('isScholarship')) return null;
        return (
            <Grid item xs={12} className="selectRadioLabel MuiFormControl-fGWhite margin-InlineForm inlinescholarship">
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isScholarship}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Would you like to know about scholarships?"
                    name="isScholarship"
                    ariaLabel="Would you like to know about scholarships?"
                />
            </Grid>
        );
    } 

    renderOptionIsMilitary() {
        return (
            <Grid item xs={12} className="selectRadioLabel MuiFormControl-fGWhite margin-InlineForm">
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isMilitary}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Do you have any U.S. Military affiliation?&nbsp;*"
                    name="isMilitary"
                    ariaLabel="Do you have any U.S. Military affiliation?"
                />
            </Grid>
        );
    }

    /*renderCheckboxHasStateConsent() {
        return (this.state.stateProvince === 'IN'
            ? (
                <Grid item xs={12} className="MuiFormControl-fGWhite margin-InlineForm">
                    <FormikCheckbox
                        type="checkbox"
                        id="hasConsent"
                        name="hasConsent"
                        className="disclaimer-checkbox"
                        onChangeCallback={this.handleStateConsent}
                        label="I am an Indiana resident. By clicking this box, I expressly give University of Phoenix authority and permission to call me regarding its educational programs and services at the telephone number I have provided."
                    />
                </Grid>
            ) : null
        );
    } */

    renderCheckboxIsCBE() {
        return (conditionalCBE(this.ref.form?.current?.values.fieldOfStudy, this.ref.form?.current?.values.degreeLevel)
            || this.isFieldEnabled('isCBE')
            ? (
                <Grid item xs={12} className="MuiFormControl-fGWhite margin-InlineForm">
                    <div className='cbe'>
                        {/*<FormikCheckbox
                            type="checkbox"
                            id="isCBE"
                            name="isCBE"
                            className="cbe-checkbox"
                            onChangeCallback={this.handleChange}
                            label={(
                                <div>
                                    I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>
                                </div>
                            )}
                        /> */}
                        <SingleCheckbox
                            className="cbe-checkbox"
                            id="isCBE"
                            label={unescapeHTML(this.getCBEText())}
                            /* ariaLabel={unescapeHTML(this.getCBEText())} */
                            checkboxName="isCBE"
                            parentCallback={this.isCBEConsent}
                            // defaultChecked={initialValues.isCBE}
                            defaultValue={this.ref.form.current.values.isCBE}  
                        />
                    </div>
                </Grid>
            ) : null
        );
    }

    renderLegalText() {
        return (
            <div className="disclaimer">
                {unescapeHTML(disclaimerText)}
            </div>
        );
    }
    renderRequiredInformation() {
        return (
            <div className="reqinfo" style={{color:"#fff", paddingLeft:"2rem"}}>
                * Required Information
            </div>
        );
    }
    renderNextButton() {
        return (
            <Button
                name='next'
                size="large"
                type="button"
                className="btn-submit inline-rfi-btn"
                onClick={e => this.handleNextButton(e)}
                style={{height:"40px"}}
            >
                Next step
            </Button>
        );
    }

    renderSubmitButton() {
        return (
            <Button name='submit' id="inlineRfiSubmit" size="large" type="submit" className="btn-submit inline-rfi-btn">
                Request information
            </Button>
        );
    }
}

DynamicRFIInlineForm.defaultProps = {
    classes: {},
    title: null,
    description: null,
    theme: 'default',
    fieldsEnabled: '',
    fieldsHidden: '',
    fieldOfStudyOptions: null,
    degreeLevelOptions: null,
    // fieldOfStudy: 'healthcare'
};

DynamicRFIInlineForm.propTypes = {
    theme: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    classes: PropTypes.instanceOf(Object),
    fieldsEnabled: PropTypes.string,
    fieldsHidden: PropTypes.string,
    fieldOfStudyOptions: PropTypes.string,
    degreeLevelOptions: PropTypes.string,
};

const DynamicRFIInlineFormStyled = withStyles(styles)(DynamicRFIInlineForm);

const generateClassName = createGenerateClassName({
    productionPrefix: 'inlineRFI',
});

export default function (props) {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <DynamicRFIInlineFormStyled {...props} />
        </StylesProvider>
    );
}
