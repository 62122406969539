import { useTheme } from '@mui/material/styles';

const color = '#3c2320';
const employerStyles = () => {
  const theme = useTheme();
  return {
    root: {
      position: 'relative',
      // margin: theme.spacing(3),
      // color: 'white',
      fontFamily: 'Roboto, arial, sans-serif',
      fontSize: 14,
      paddingBottom: '0px',
      paddingTop: '0px',
      // width: 'calc(100% - -3rem)',
      maxWidth: '128rem',
      margin: 'auto',
      '& .fix-notched-outline fieldset': {
        top: '0',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        // color: '#b21f13 !important',
        fontWeight: '400',
      },
      '& .Mui-error .MuiInputBase-input:before': {
        paddingLeft: '20px',
      },
  
      '@media (max-width: 450px)': {
        width: '100%',
        margin: 2,
        fontSize: 14,
      },
      '& form h3': {
        fontSize: '16px',
        margin: 0,
      },
      '& .ways2save-checkbox .MuiFormControlLabel-label': {
        fontWeight:'500',
      },
      '& .selectRadioLabel .MuiFormControlLabel-label': {
        fontWeight:'500',
      },
      '& .legal-text': {
        paddingTop:'0!important',
      },
  
    },
  
    inlineFormTitle: {
      padding: '2rem',
      paddingBottom:'1rem',
      '& h1, & h2, & h3, & a': {
        // color: 'white !important',
        textDecoration: 'none',
        fontWeight: 'bold !important',
        lineHeight: '30px',
        marginTop: 0,
        '@media (max-width: 450px)': {
          width: '100%',
          '&.title-small': {
            fontSize: 16,
            lineHeight: '24px',
          },
        },
      },
      '& h1, & h2': {
        fontSize: '24px',
        marginBottom: '1rem',
      },
      '& h3': {
        fontSize: '16px',
        marginBottom: 20,
      },
    },
    disclaimer: {
      backgroundColor: 'white',
      color: 'black',
      padding: '25px',
      paddingBottom: '0',
    },
  
    centeredContent: {
      display: 'flex',
      '& > button': {
        flexGrow: 1,
      },
      backgroundColor: 'white',
      width: '100%',
      padding: '1em',
      paddingLeft: '2em',
      paddingRight: '2em',
    },
  
    radio: {
      color: 'white',
    },
    infoLabel: {},
    checkbox: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#001823',
      lineHeight: '18px',
      height: 'auto',
      paddingBottom: '20px',
      marginLeft: 0,  
    },
    label: {
      color,
      fontSize: 16,
      paddingBottom: 8,
      fontWeight: 'bold',
    },
    header: {
      fontSize: 20,
      lineHeight: 1.875,
    },
    stepCounter: {
      fontSize: 16,
      lineHeight: '24px',
      borderBottom: '3px solid #d2d7d9',
      height: 40,
    },
    formGroup: {
      margin: theme.spacing(3, 0),
    },
    customBtn: {
      '&:hover': {
        backgroundColor: '#de3518',
        color: '#fff',
      },
      textAlign: 'center',
      backgroundColor: '#de3518',
      borderRadius: 6,
      color: '#fff',
      fontSize: 18,
      fontWeight: 'bold',
      borderColor: '#de3518',
    },
    moreInfoMessage: {
      fontSize: 12,
      marginTop: 25,
      lineHeight: '18px',
    },
    submitConsent: {
      fontSize: 12,
      marginBottom: 25,
      lineHeight: '18px',
    },
    backBtn: {
      fontSize: 16,
      paddingLeft: 0,
      marginTop: 10,
    },
    mobileGrid: {
      marginTop: '14px',
    },
    autoCompleteContainter: {
      width: '100%',
      maxWidth: '100%',
      height: '150px',
      position: 'absolute',
      minWidth: '266px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: '#fff',
      borderRadius: '4px',
      'margin-top': '4px',
      'z-index': '9999',
      'padding-top': '4px',
      'box-shadow': '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  
    },
    autoCompleteUl: {
      width: '100%',
      height: '156px',
      position: 'absolute',
      minWidth: '266px',
      listStyle: 'none',
      paddingLeft: 0,
      margin: 'auto !important',
  
    },
    autoCompleteLi: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      'background-color': '#fff',
      'letter-spacing': '0.00938em',
      'font-weight': '400',
      height: '24px',
      'font-size': '16px !important',
      'border-color': '#000',
      'line-height': '1.5',
      'padding-top': '6px',
      'padding-bottom': '6px',
      'padding-left': '16px',
      'padding-right': '16px',
      'white-space': 'nowrap',
      width: 'auto',
      overflow: 'hidden',
      display: 'block',
      'min-height': '48px',
    },
    suggestionContainer: {
      width: '200%',
    },
  
    stepSelectionContainer: {
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: '0',
      marginLeft: '-1rem',
      marginRight: '-1rem',
      marginBottom: '1rem',
      paddingTop: 0,
    },
  
    stepSelection: {
      padding: '6px',
      background: 'transparent',
      border: '0',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      transition: 'transform 1s',
      transform: 'scale(1)',
      '&:hover': {
      },
      '&:focus': {
        border: '2px #001823',
        borderRadius: 0,
      },
    },
    stepSelectionText: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    navCircle: {
      width: '16px',
      height: '32px',
      borderRadius: '50%',
      padding: '2px 7px',
      color: '#CEECF2',
      background: '#09687D',
      fontWeight: 400,
      border: 0,
      textAlign: 'center',
      margin: '5px',
      textDecoration: 'none !important',
      '&.active': {
        fontWeight: 700,
        color: '#fff',
        background: '#0D819C',
      }
    }
  }
};

export default employerStyles;
